@import "theme.scss";

.app {
  position: fixed;
  top: 42px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: $color-waterblue;

  section {
    position: fixed;
    top: 85px;
    bottom: 0;
  }

  &.info-panel-open {

    .info-panel {
      transform: translateX(0);
    }
    // .map {
    //   left: 860px;
    //   right: 0;
    // }
  }
}

.loading-container {
  padding: 60px 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-text {
    font-size: $font-size-large;
    line-height: 125%;
    height: 20px;
    vertical-align: middle;

    .spinner {
      display: inline-block;
      vertical-align: sub;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: url("../../static/img/icon-progress.svg") center center no-repeat;
      animation: spin 0.6s infinite linear;
      @keyframes spin {

        100% {
          -moz-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
}
