@import "theme.scss";

.ReactModal__Overlay--after-open {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  outline: none;
  overflow-y: auto;
}

.ReactModal__Content--after-open,
.modal-window {
  position: relative;
  margin: 100px auto;
  width: 600px;
  padding: 30px;
  background-color: $color-white;
  border-radius: 2px;
  z-index: 101;
  outline: none;
}

.modal-window {

  .modal-image-header {
    background: $color-grey70;
    margin: -30px -30px 30px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    height: 133px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    h3 {
      font-size: $font-size-large;
      line-height: 100%;
      margin: 0;
    }
  }

  .asp-select-box {
    box-shadow: 0px 4px 10px rgba($color-black, 0.25);
    border-radius: 4px;
    padding: 16px 19px;
    margin-bottom: 24px;

    .modal-fields {
      margin-bottom: 6px;
    }
  }

  .close-modal {
    margin-left: auto;
    width: 16px;
    height: 16px;
    padding: 2px;
    opacity: 0.7;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    svg {
      stroke: $color-grey60;
    }

    &:hover {

      svg {
        stroke: $color-grey40;
      }
    }

    &:active {

      svg {
        stroke: $color-grey20;
      }
    }
  }

  .modal-fields {
    width: 48%;
    display: inline-block;
    margin-bottom: 24px;

    &--right {
      float: right;
    }

    &--full {
      width: 100%;
    }

    &--domain {
      width: 80%;
    }

    &--button {
      width: 35%;
    }

    &--checkbox {

      .checkbox-container {
        height: auto;
      }

      p {
        margin: 0;
        display: inline-block;
      }
    }

    input[type="radio"] {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    &.embedded-button-input {
      position: relative;
    }

    p {
      font-size: $font-size-medium;
      line-height: 143%;
    }

    label {
      display: block;
      font-size: $font-size-medium;
      margin-bottom: 10px;
    }

    select {
      display: block;
      width: 100%;
      height: 40px;
      font-size: $font-size-medium;
      margin-bottom: 24px;
      border: 1px solid $color-grey60;
      outline: none;

      &.focus {
        border: 1px solid $color-black;
      }
    }

    .Dropdown-root {
      font-size: $font-size-medium;

      .Dropdown-control {
        border: 1px solid $color-grey60;

        .Dropdown-placeholder {
          color: $color-grey60;

          &.is-selected {
            color: $color-black;
          }
        }
      }

      .Dropdown-arrow {
        top: 17px;
        right: 14px;
      }

      &.is-open {

        .Dropdown-control,
        .Dropdown-menu {
          border: 1px solid $color-black;
        }

        .Dropdown-arrow {
          border-color: transparent transparent $color-black;
        }
      }
    }

    .embedded {
      position: absolute;
      right: 3px;
      bottom: 6px;
      top: 35px;
    }

    .form-note {
      color: $color-grey60;
      font-size: $font-size-medium;
      line-height: 100%;
    }

    .template {
      background-color: $color-grey80;
      border-radius: 2px;
      height: 41px;
      padding: 11px;
      font-weight: $font-weight-normal;
      font-size: $font-size-large;
      line-height: 130%;
      color: $color-grey20;

      .remove {
        float: right;
        margin-right: 4px;
        font-size: $font-size-medium;
        color: $color-red;
        cursor: pointer;
      }
    }
  }

  .modal-submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .blue-button {
    background: none;
    color: $color-blue;
    border: 1px solid $color-blue;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font-size-large;
    width: 100%;
    margin-top: 18px;

    p {
      padding: 14px 43px;
      margin: 0;
    }

    &:hover {
      background: rgba($color-blue, 0.12)
    }

    &:active {
      background: $color-blue;
      color: $color-white;
    }

    &.disabled {
      color: $color-grey80;
      background-color: $color-grey70;
      border: 1px solid $color-grey80;
      pointer-events: none;
    }

    &.hide {
      border: none;
      pointer-events: none;
    }
  }

  .loading {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: sub;
    margin-right: 10px;
    background: url("../../static/img/icon-progress.svg") center center no-repeat;
    animation: spin 0.6s infinite linear;
    @keyframes spin {

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .toggle-label {
    padding: 0 12px;
    display: inline-block;
    margin: 10px 0;
  }

  .modal-title-and-description {

    b {
      display: block;
    }
  }

  .domain-input {
    margin-bottom: 12px;

    .delete {
      height: 24px;
      width: 24px;
      cursor: pointer;
      left: 73%;
      position: absolute;
      margin-top: 8px;
      z-index: 1;
    }

    .textInput {
      width: 80%;
      display: inline-block;
    }

    .toggle-button {
      float: right;
    }
  }

  .button-domain {
    height: 30px;
    border-radius: 2px;
    border: none;
    outline: none;
    margin-left: 10px;
    font-size: $font-size-medium;
    cursor: pointer;
    color: $color-grey20;
    padding-left: 10px;
    padding-right: 10px;
    background: $color-grey90 none left 8px center no-repeat;

    &:hover {
      background-color: $color-grey80;
    }

    &:active {
      background-color: $color-grey60;
    }
  }

  .horizontal-line {
    border-bottom: 1px solid $color-grey60;
    opacity: 0.25;
    overflow: hidden;
    margin-bottom: 24px;
  }

  .domains-list-title {
    display: inline-block;
    font-size: $font-size-medium;

    &--right {
      float: right;
    }
  }

  .modal-text {

    p:first-child {
      margin-top: 0;
    }
  }

  .radio-list {

    p {
      margin-left: 24px;
    }

    label:last-child {
      margin-bottom: 0px;
    }
  }
}
