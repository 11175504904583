@import "theme.scss";

.map {
  left: 360px;
  right: 0;
  z-index: 1;
  transition: all 500ms; /*cubic-bezier(1, 0, 0, 1);*/
  overflow: auto;

  &.panel-open {
    left: 820px;

    &.isSidebarCollapsed {
      left: 480px;
    }
  }

  &.isSidebarCollapsed {
    left: 20px;
  }

  /* Lower order class styles
    |
    | .saving {}
    |
  */

  .leaflet-container {

    .smallLabel{
      max-width: 5rem;
      max-height: 2rem;
      font-size: $font-size-very-small;
      padding: 4px;

      &.smallIcon-tooltip{
        margin-top: 6px;
      }
    }

    .mediumLabel{
      margin-top: 6px !important;
    }

    .verySmallLabel{
      margin-top: -6px !important;
    }

    .smallestLabel{
      max-width: 3rem;
      max-height: 1.5rem;
      font-size: $font-size-smallest;
      padding: 2px;
    }
    background: $color-mapgreyblue;

    .leaflet-tooltip-top{
      margin-top: 0;

      div{
        max-width: 7rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.old-map-background {
      background: $color-mapblue;
    }

    .leaflet-popup.context-menu {
      margin: 0;

      .leaflet-popup-content-wrapper {
        padding: 10px 0;
        background: $color-white;
        border-radius: 0;
        box-shadow: 0 0 10px rgba($color-black, 0.25);
        color: $color-grey60;

        button {
          padding: 5px 15px;
          width: 100%;
          cursor: pointer;
          border: none;
          background: none;
          color: $color-black;
          font-size: $font-size-medium;
          text-align: left;
          font-style: normal;
          line-height: 150%;

          &:hover {
            background-color: rgba($color-blue, 0.25);
          }
        }
      }

      .leaflet-popup-content {
        margin: 0;
        width: 230px;
      }

      .leaflet-popup-tip-container,
      .leaflet-popup-close-button {
        display: none;
      }
    }
  }

  .ioa-marker {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.metroActive {

      svg:hover {

        .bg {
          fill: $color-maroon;
        }
      }

      &.underAcquisition {

        svg {

          .bg {
            fill: $color-orange;
          }

          .border {
            stroke: $color-orange;
          }
        }

        svg:hover {

          .bg {
            fill: $color-orange;
          }
        }
      }
      // IMPORTANT:
      // Have .existing class LAST, so that it has priority
      // when multiple classes are applied.

      &.existing {

        svg {

          .bg {
            fill: $color-vividgreen;
          }

          .border {
            stroke: $color-darkgreen;
          }
        }

        svg:hover {

          .bg {
            fill: $color-darkgreen;
          }
        }
      }
    }

    &.location {

      svg:hover {

        .bg {
          fill: $color-black;
        }

        .fg {
          fill: $color-white;
        }
      }
    }

    &.selected {

      &.metroActive {
        filter: drop-shadow(0px 0px 10px $color-red);

        &.underAcquisition {
          filter: drop-shadow(0px 0px 10px $color-orange);
        }

        &.existing {
          filter: drop-shadow(0px 0px 10px $color-vividgreen);
        }
      }

      &.location {
        filter: drop-shadow(0px 0px 10px $color-grey50);
      }

      svg {

        circle.bg {
          fill: $color-white !important;
        }

        circle.border {
          stroke: $color-white !important;
        }

        path.fg {
          fill: $color-black !important;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .saving-toast {
    z-index: 400;
    position: absolute;
    right: 54px;
    bottom: 27px;
    width: 180px;
    height: 62px;
    padding: 15px;
    background-color: $color-grey10;
    border-radius: 4px;
    display: flex;
    justify-content: center;

    .spinner {
      background: url("../../../static/img/icon-progress.svg") center center no-repeat;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      animation: spin 0.6s infinite linear;
      @keyframes spin {

        100% {
          -moz-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }

    p {
      margin: 0;
      line-height: 188%;
      color: $color-grey70;
    }
  }
}

.gray-overlay {

  &.leaflet-interactive {
    cursor: auto;
  }
}

.map > * {
  height: 100%;
}

.map-button {
  box-shadow: 0px 1px 3px rgba($color-black, 0.1);
  border-radius: 4px;
  border: none;
  line-height: 100%;
  font-size: $font-size-medium;
  background-color: $color-white;
  background-position: 15px;
  background-repeat: no-repeat;
  color: $color-black;
  height: 40px;
  padding: 13px 13px 13px 42px;

  &.leaflet-control {
    cursor: pointer;
  }

  &.marketplace-button {
    clear: none;
    background: url("../../../static/img/icon-marketplace.svg") left center no-repeat;
    background-position-x: 12px;
    background-color: $color-white;

    &.open {
      background-image: url("../../../static/img/icon-marketplace-white.svg");
    }

    &.active:not(.open) {
      background-image: url("../../../static/img/icon-marketplace-blue.svg");
    }
  }
}

.leaflet-control-zoom {
  box-shadow: 0px 1px 3px rgba($color-black, 0.1) !important;
  border-radius: 4px;
}

.leaflet-control.latency-disclaimer {
  color: $color-red;
}

.map-button:hover {
  opacity: 1;
}

.map-button:active {
  box-shadow: 0px 1px 2px rgba($color-black, 0.25);
  background-color: $color-red;
}

.map-button.snapshot-button {
  background-image: url("../../../static/img/icon-snapshot.svg");
  clear: none;
}

.map-button.layers-button {
  background-image: url("../../../static/img/icon-layers-black.svg");
  clear: none;

  &.open {
    background-image: url("../../../static/img/icon-layers-white.svg");
    background-color: $color-red;
    color: $color-white;
  }
}

.map-button.layers-button,
.map-button.marketplace-button {
  position: relative;
  padding-right: 35px;

  &::after {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../../../static/img/icon-dropdown-arrow-black.svg");
    background-repeat: no-repeat;
  }

  &.open {
    background-color: $color-red;
    color: $color-white;

    &::after {
      background-image: url("../../../static/img/icon-dropdown-arrow.svg");
      transform: translateY(-50%) scaleY(-1);
    }
  }
}

.marketplace-menu {
  background: $color-white;
  position: absolute;
  padding: 0 10px;
  z-index: 1001;
  top: 58px;
  right: 206px;
  box-shadow: 0px 0px 4px rgba($color-black, 0.15);
  border-radius: 4px;
  cursor: default;
  height: auto;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 117%;
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;

  &.visible{
    padding: 10px;
    max-height: 750px;
  }

  .separator-line {
    position: relative;
    width: inherit;

    &::before {
      content: "";
      position: absolute;
      border-top: 1px solid $color-grey80;
      height: 1px;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  ul {
    list-style: none;
    width: 100%;
    margin: 0;
    white-space: nowrap;
    border-radius: 2px;
    padding: 5px;

    &.segment-list {
      position: relative;
      padding-bottom: 0;
      max-height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &.actions-list {
      margin: 0;

      li {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
        }

        &:hover {
          background: $color-grey80;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    &.subsegment-list {
      padding: 0;

      li {
        font-size: $font-size-small;
      }
    }

    li {
      font-size: $font-size-medium;
      line-height: 130%;
      margin-bottom: 4px;
      padding: 6px;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background: $color-grey80;
      }

      &.selected {
        background: $color-blue;
        color: $color-white;
      }
    }
  }

  .type-selection {
    width: 100%;
    border: 1px solid $color-grey80;
    border-radius: 4px;
    padding: 5px;
    font-size: $font-size-medium;
    line-height: 129%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 50%;
      text-align: center;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;

      &.selected {
        background: $color-grey15;
        color: $color-white;
      }
    }
  }
}

.layersMenu {
  background: $color-white;
  position: absolute;
  z-index: 1001;
  top: 58px;
  right: 46px;
  box-shadow: 0px 0px 4px rgba($color-black, 0.15);
  border-radius: 4px;
  user-select: none;
  cursor: default;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 117%;
  width: 270px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.visible{
    max-height: 75vh;
  }

  &.apply-changes {
    right: 0;
    left: 380px;
  }

  .layersWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
    height: 75vh;
  }

  .clickable-item {
    cursor: pointer;
  }

  h5 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: $font-size-very-small;
    line-height: 120%;
    letter-spacing: 1px;
    color: $color-grey60;
    margin-top: 20px;
  }

  h6 {
    text-transform: capitalize;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    line-height: 117%;
    margin: 0;
  }

  .milliseconds {
    position: relative;
    width: 54px;

    input.latency-ms-input {
      width: inherit;
      padding: 4px 10px !important;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      border: 1px solid $color-grey80;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &::after {
      position: absolute;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "ms";
    }
  }

  .ring-radius-wrapper {
    margin-top: 5px;
  }

  .ring-color-wrapper {
    height: 24px;
  }

  .range {
    padding: 10px 0 20px 0;
  }

  .section-title{
    padding-bottom: 7px;
  }

  .main-section-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .section-item {
    padding-bottom: 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .section-select-group {
      padding: 0;
    }
  }


  .checkbox-container {
    width: 27px;
    height: 14px;
  }

  .subsection-title {
    color: $color-grey20;
    align-items: center;
    display: flex;
    white-space: pre;

    .info-icon {
      margin-left: 2px;
    }
  }

  .select-wrapper {
    max-width: 45%;
    flex: 1;

    :first-child {
      flex: 2;
    }

    div {
      cursor: pointer;
    }

    .select__menu {
      box-shadow: 0px 1px 4px rgba($color-black, 0.25);
      padding-right: 0;
      width: max-content;
      min-width: 100%;
      z-index: 2000;
    }
  }

  .select__control {
    height: 25px;
    font-size: $font-size-small;
    background: $color-grey90;
    min-height: 20px;
    border: none;
    box-shadow: none;

    &:hover {
      border: none;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__dropdown-indicator {
      padding: 0;
    }
  }

  ul {
    padding-inline-start: 0;
    list-style: none;
    line-height: 117%;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0;
      width: 100%;
    }
  }
}

.horizontal-line {
  border-bottom: 1px solid $color-grey60;
  opacity: 0.25;
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-inline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 30px;
}

.dropdown-section{
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.visible{
    max-height: 400px;
  }
}

button.toggle-button {
  height: 12px;
  width: 20px;
  padding: 0 1px;
  margin: 0;

  &:before {
    height: 10px;
    width: 10px;
  }

  &.checked:before {
    transform: translateX(8px);
  }
}

.imagesWrapper {
  margin-bottom: 15px;
}

.image {
  padding: 1px;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  margin-right: 12px;

  &.selected {
    box-shadow: inset 58px 36px $color-blue;
  }
}
