@import "theme.scss";

.export {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-black,0.5);
  z-index: 4;
  padding: 20px;
  overflow: scroll;

  .modal {
    background: $color-white;
    max-width: 1162px;
    margin: 75px auto;
    padding: 40px;
    position: relative;

    .close {
      border: none;
      font-size: $font-size-heading-large;
      line-height: 40%;
      font-weight: $font-weight-light;
      position: absolute;
      right: 33px;
      cursor: pointer;
    }

    h2 {
      font-family: "Proxima Nova", arial, sans-serif;
      font-size: $font-size-heading-medium;
      line-height: 115%;
      margin-top: 0;
    }

    .exportAll {
      border: none;
      background: $color-grey90;
      font-size: $font-size-medium;
      line-height: 154%;
      margin-left: 15px;
      padding: 2px 10px;
    }

    .hiddenMap {
      width: 0;
      height: 0;
      overflow: hidden;

      & > div {
        width: 4096px;
        height: 2160px;
      }
    }

    .maps {
      display: flex;
      justify-content: space-between;
      margin-bottom: 55px;
    }

    .mini-map {
      width: 240px;
      height: 165px;
      position: relative;
      left: 0;

      & > div {
        height: 100%;
      }

      .leaflet-control {
        display: none;
      }

      .loading {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        background: rgba($color-white, 0.5);

        .spinner {
          position: absolute;
          top: 65px;
          left: 105px;
          width: 30px;
          height: 30px;
          background: url("../../../static/img/icon-progress.svg") center center no-repeat;
          background-size: contain;
          animation: spin .6s infinite linear;
          z-index: 999;
        }
      }

      .download-link {
        position: absolute;
        z-index: 990;
        bottom: 10px;
        left: 10px;
        height: initial;
        color: $color-white;
        text-decoration: none;
        font-size: $font-size-medium;
        background: none;
        border: none;

        > * {
          pointer-events: none;
        }

        .icon {
          background: rgba($color-black, 0.5);
          padding: 5px 10px;
          border-radius: 2px 0px 0px 2px;
          margin-right: 1px;
        }

        .text {
          background: rgba($color-black, 0.5);
          padding: 5px 10px;
          border-radius: 0px 2px 2px 0px;
        }
      }
    }

    .deployment-plan-link {
      display: inline-block;
      padding: 15px 30px;
      background: $color-grey80;
      text-decoration: none;
      border-radius: 2px;
      margin-bottom: 50px;
      color: $color-black;

      &:hover {
        background: $color-grey70;
      }
    }

    .pdf-reports {
      display: flex;
      justify-content: space-between;

      .report-container {
        border: 1px solid $color-grey70;
        border-radius: 4px;
        flex: 1;
        max-width: 100%;
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }

        .report-topbar {
          height: 43px;
          display: flex;
          align-items: center;
          padding: 15px;
          background: $color-grey90;
          font-weight: $font-weight-bold;
          font-size: $font-size-medium;
          font-family: Lato, sans-serif;

          .report-title {
            margin-right: auto;
          }

          a {
            text-decoration: none;
            margin-left: 10px;

            svg {
              margin-left: 7px;
            }
          }
        }

        .report-content {
          overflow: scroll;
          /* Intentionnally make these very small, their proper
             size will be computed with Javascript. They need to be
             small so they don't "push" on the parent element, which
             will then take its dimensions from the flex sizing.
          */
          width: 1px;
          height: 1px;
        }

        .frame-container {
          overflow: hidden;
        }

        .report-frame {
          width: 1800px;
          height: 1110px;
          /* Scaled in Javascript */
          transform-origin: top left;
          border: none;
        }
      }
    }
  }
}
