@import "theme.scss";

.map {

  .labelMarker {
    cursor: move;
  }

  .labelMarker .label {
    white-space: nowrap;
    width: fit-content;
    background: $color-white;
    padding: 7px 15px;
    border: 1px solid rgba($color-black, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
    border-radius: 2px;
    font-size: $font-size-small;
    line-height: 191%;
    transform: translate(-50%, -50%);

    &:hover {
      border: 1px solid $color-black;
    }

    &.small {
      padding: 3px 5px;
      font-size: $font-size-smallest;
      line-height: 129%;
      border-radius: 1px;
    }

    &.small.mini {
      padding: 1px 3px;
      font-size: $font-size-smallText;
      line-height: 125%;
    }
  }

  .labelMarker.dragging .label {
    border: 1px solid $color-interactiveblue;
    box-shadow: 0px 1px 5px $color-interactiveblue;
  }
}
