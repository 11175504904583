@import "theme.scss";

.info-wrapper {
  background: $color-white;
  padding: 15px 20px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;

  p {
    font-size: $font-size-heading-small;
    margin: 0 15px;
  }
}