@import "theme.scss";

.collaborators-modal {

  .embedded-button-input {

    input.textInput {
      height: 52px;
    }

    .loading{
      top: 43px;
    }
  }

  .collaborators-table-wrapper {

    .collaborators {

      tbody {
        display: block;
        max-height: 260px;
        overflow-y: auto;
        margin-top: 0;
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      tr:hover {
        background: none !important;
      }

      th {
        padding: 0;
      }
      font-size: $font-size-medium;
      text-align: left;

      .collaborator {
        padding: 12px;
        font-size: $font-size-small;
        line-height: 130%;
        position: relative;
        border-top: 1px solid rgba($color-black, 0.1);

        td {
          border-bottom: none;
        }

        &-email {
          color: $color-grey20;
        }

        &:first-child {
          border: none;
        }

        .status-name {
          text-transform: capitalize;
          display: flex;
          align-items: center;
          padding-left: 0;

          svg {
            margin-right: 15px;
          }

          &.viewing {
            color: $color-interactiveblue;
          }

          &.editing {
            color: $color-vividgreen;
          }

          &.offline {
            color: $color-grey60;
          }
        }

        .loading,
        button {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
          margin:0;
        }

        button {
          cursor: pointer;
          background: url("../../../static/img/icon-trash.svg") center center no-repeat;
          border: none;
          border-radius: 2px;
          height: 14px;

          &:hover {
            background-color: $color-grey80;
          }

          &:active {
            background-color: $color-grey60;
          }
        }
      }
    }
  }
}
