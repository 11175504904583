@import "theme.scss";

.manage-metros {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-black, 0.5);
  z-index: 4;
  padding: 20px;
  overflow: scroll;

  .modal {
    background: $color-white;
    max-width: 1162px;
    margin: 75px auto;
    padding: 40px;
    position: relative;

    .close {
      border: none;
      font-size: $font-size-heading-large;
      line-height: 100%;
      font-weight: $font-weight-light;
      position: absolute;
      right: 33px;
      cursor: pointer;
    }

    h2 {
      font-family: "Proxima Nova", arial, sans-serif;
      font-size: 20px;
      line-height: 115%;
      margin-top: 0;
    }

    .regions {
      display: flex;

      .region {
        flex: 1;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }

        h3 {
          font-weight: $font-weight-normal;
          font-size: 14px;
          color: $color-grey60;
        }

        table.wb-table tbody {
          background: rgba($color-grey90, 0.5);
          box-shadow: none;
        }
      }
    }

  }
}