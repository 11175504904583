@import "theme.scss";

.team {
  cursor: default;

  .select-wrapper {
    width: 150px !important;
  }

  .user__control {
    box-shadow: 0px 1px 3px rgba($color-black, 0.1) !important;
    min-height: 34px !important;
    height: 34px;
    width: 150px;
    margin-right: 10px;
  }
}

.role {
  cursor: pointer;
  margin-right: 20px;
}

.going-back-button {

  svg {
    transform: rotate(180deg);
  }

  button {
    border: none;
    cursor: pointer;
    padding: 31px 0 0 0;
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    background: none;
    font-size: $font-size-medium;
    align-items: center;

    &:hover {
      background: $color-grey90;
    }
  }
}

.list-subheader {
  font-size: $font-size-medium;
  color: $color-grey60;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 0px 52px;

  span {
    width: 100px;
    text-align: center;
    margin-right: 10px;

    &.roles-subheader {
      margin-right: 0;
      width: 150px;
      display: flex;
      align-items: center;
      gap: 3px;
      justify-content: center;

      svg {
        cursor: pointer;
      }
    }
  }
}

.information-wrapper {
  margin-right: 10px;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.trash {
  cursor: pointer;
  padding: 7px 0 0 13px;
  width: 28px;
}

.button-section {
  display: flex;
  align-items: center;
  gap: 6px;
}

.current-user {
  box-shadow: 0px 1px 3px rgba($color-black, 0.1);
  background-color: $color-white;
}

.role-information {
  width: 150px;
  text-align: center;
}
