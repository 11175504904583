@import "theme.scss";

.AddressAutocompleteWithType {
  display: flex;
  z-index: 2;
  border: 1px solid $color-grey60;

  .select-wrapper {
    width: 15%;
    cursor: pointer;


    .option-select-type {
      display: flex;
      gap: 5px;
      padding: 10px 15px;
      font-size: $font-size-medium;
      flex-wrap: nowrap;


      svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    .select__menu {
      width: 150px;
    }

    .select__indicators {
      display: none;
    }

    .select__control {
      cursor: pointer;
      border-right: none;
      height: 40px;
      border: none;
      box-shadow: none;

      .select__value-container {
        flex-wrap: nowrap;
      }


      svg {
        width: 35px;
        height: 20px;
      }

      >div::before {
        content: "▼";
        padding-left: 31px;
        font-size: $font-size-very-small;
        position: absolute;
      }
    }
  }



  &:focus-within,
  &:hover {
    border: 1px solid $color-blue;
  }

}