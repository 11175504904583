@import "theme.scss";

.template-card{
  height: 380px;
  width: 285px;
  background: $color-white;
  box-shadow: 0px 1px 3px rgba($color-black, 0.1);
  border-radius: 2px;
    
  
  .image-container{
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  
    img{
      flex-shrink: 0;
      width: 100%;
      height: 100%
    }
  
    .saved-information{
      top: 10px;
      right: 10px;
      position: absolute;
      background: $color-white;
      border-radius: 3px;
      padding: 5px;
      font-weight: $font-weight-normal;
      font-size: $font-size-medium;
      line-height: 0%;
      color: $color-grey20;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      box-shadow: 0px 1px 3px rgba($color-black, 0.1);
   
      svg{
          
        path{padding: 0;}
      }
    }
  
    .long{
      padding:0;
      left: 10px;
      align-items: center;
      justify-content: space-between;
    }
  
    .item{
      padding: 5px 7px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
    }
  
    .fill{
      flex: 1;
      justify-content: flex-start;
    }
  
    .end{
      height: 100%;
      border-left: 1px solid $color-grey80;
  
      // svg{
      //   margin-top: 2px;
      // }
    }
  
    .public{
      right: auto;
      left: 10px;
    }
  }
  
  .information{
    height: 190px;
    padding: 15px 15px 20px 15px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  
    .title{
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      h3{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
        line-height: 110%;
        margin: 0;
        color: $color-grey20;
      }
  
      svg{
        cursor: pointer;
      }
    }
  
      
    .with-icon{
  
      p{
        padding: 0px 15px 0px 5px;
      }
    }
  
    .small{
      display: flex;
      align-items: center;
  
      p{
        margin: 0;
        font-weight: $font-weight-normal;
        font-size: $font-size-small;
        line-height: 130%;
        color: $color-grey50;
      }
    }
  
    .tags{
      display: flex;
      gap: 7px;
      height: 23px;
      align-items: center;
      font-weight: 400;
      font-size: $font-size-small;
      color: $color-grey50;
  
  
      .container{
        line-height: 130%;
        border: 1px solid $color-grey80;
        border-radius: 3px;
        padding: 3px 5px 2px 5px;
        white-space: nowrap;
  
      }
  
      .tooltip-container{
        position: relative;
        display: inline-block;
  
        .tooltip-text{
          visibility: hidden;
          width: 120px;
          background-color: $color-black;
          color: $color-white;
          text-align: center;
          padding: 5px 0;
          border-radius: 6px;
          position: absolute;
          z-index: 1;
          white-space: pre-line;
            
        }
  
        &:hover{
  
          .tooltip-text{visibility: visible;}
        }
      }
    }
  
    .description{
      height: 55px;
      margin: 0;
      min-width: 100%;
      font-weight: $font-weight-normal;
      font-size: $font-size-medium;
      line-height: 130%;
      color: $color-grey50;
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  
  .buttons-container{
    border-top: 1px solid $color-grey80;
    display: flex;
    height: 50px;
      
    .button{
      width: 40%;
      padding: 0;
      margin: 0;
      border-radius: 0 0 2px 0;
  
      &:hover{

        span{color: $color-white};
        background: $color-grey50;
      }
    }
  
    .button:first-child{
      width: 60%;
      border-right: 1px solid $color-grey80;
      border-radius: 0 0 0 2px;
  
      &:hover{
        background: $color-red;

        span{color: $color-white};
      }
    }
  }
}