@import "theme.scss";

.AddView {
  display: grid;
  grid-template-rows: 70px auto 70px;
  height: 100%;
  overflow: auto;

  h2 {
    grid-row: 1;
    margin: 0;
    padding: 25px 0 26px 30px;
    padding-right: 60px;
    font-size: $font-size-heading-medium;
    line-height: 100%;
    font-weight: $font-weight-bold;
    color: $color-black;
    border: none;
    border-bottom: 1px solid rgba($color-black, 0.2);
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .register{
      font-size: $font-size-small;
      position: absolute;
      margin-top: -4px;
    }
  }

  h3 {
    margin: 20px 0;
    font-size: $font-size-large;
  }

  .content-section h3 {
    margin-top: 0; // this one already has a top padding
  }

  .loading {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: sub;
    margin-right: 10px;
    background: url("../../../../static/img/icon-progress.svg") center center no-repeat;
    animation: spin .6s infinite linear;
    @keyframes spin {

      100% {
        transform:rotate(360deg);
      }
    }
  }

  .platform-section {
    margin-bottom: 40px;

    .edge-select {
      margin-bottom: 8px;
    }

    label {
      margin-bottom: 15px;
      display: block;
      font-size: $font-size-medium;
      line-height: 129%;
    }
  }

  .where {
    overflow-y: auto;
    grid-row: 2;
    padding: 30px;

    p {
      margin: 0;
      font-size: $font-size-medium;
      color: $color-grey20;
    }

    input {
      height: 40px;
    }

    .textInput {
      margin-bottom: 20px;
    }

    .checkbox-container {
      width: 20px;
      padding: 0;
    }

    .existing {
      font-size: $font-size-medium;
      color: $color-grey60;
    }
  }

  .buttons {
    grid-row: 3;
    display: flex;
    padding: 0px 30px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  .note {

    h3 {
      margin: 0 0 15px 0;
    }
    grid-row: 4;
    padding: 30px;
  }

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    overflow-y: auto;
    user-select: none;
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid rgba($color-black, 0.2);
    margin: 0;
    padding: 15px 30px 0 30px;
    font-size: $font-size-medium;
    font-weight: $font-weight-light;
    white-space: nowrap;
  }

  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    cursor: pointer;
    margin: 0 24px 0 0;
    padding-bottom: 8px;
    color: $color-grey60;
    border-bottom: 3px solid transparent;
    font-weight: $font-weight-normal;
  }

  .react-tabs__tab--selected {
    color: $color-red;
    border-bottom: 3px solid $color-red;
  }

  .react-tabs__tab--disabled {
    color: $color-grey20;
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  .Dropdown-root {

    .Dropdown-control {

      .Dropdown-arrow-wrapper {

        .Dropdown-arrow {
          top: 18px;
          right: 14px;
        }
      }
    }
  }

  .content-section {

    .latency-disclaimer {
      margin: 10px 0 0;
      margin-bottom: 20px;
    }
  }

  .latency-disclaimer {
    margin: 30px;
    color: $color-grey60;
    font-size: $font-size-medium;
    font-weight: $font-weight-thin;
  }


}
