@import "theme.scss";

.confirmation-modal {
  width: 500px;

  .confirmation-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  }

  .modal-title {
    font-weight: $font-weight-bold;
    margin: 0;
    line-height: 100%;
    font-size: $font-size-large;
  }

  .close-modal-icon {
    stroke: $color-black !important;
  }

  .modal-action-button {
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
    line-height: 110%;
    height: 53px;
    padding: 0 40px;
    flex-basis: 45%;
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;
  }

  .modal-main-text {
    font-size: $font-size-large;
    line-height: 110%;
    padding: 18px;
    border: 1px solid $color-grey60;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }

  .trailing-text {
    font-size: $font-size-medium;
    line-height: 130%;
    margin: 16px 0 0 0;
  }

  .confirmation-loader {
    display: flex;
    align-items: center;
    justify-content: center;

    .loading {
      margin: 0;
    }
  }
}
