@import "theme.scss";

.projects-list_item {
  position: relative;
  user-select: none;
  height: 50px;
  margin-bottom: 6px;
  padding: 0 24px;
  background-color: rgba($color-white,0.3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 3px rgba($color-black, 0.1);
  transition: .1s all ease-in-out;
  border-radius: 2px;
  cursor: pointer;
    
  .project-meta {
    height: 30px;
    color: $color-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 800px;

    &.is-shared-with-me {
      max-width: 600px;
    }

    span {
      line-height: 190%;

      &.customer-name { font-weight: $font-weight-bold; padding-right: 10px; }

      &.project-name { border-left: 1px solid $color-grey20; padding-left: 12px; }
    }
  }

  .project-shared-users {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .shared {
      background: url("../../../static/img/icon-shared.svg");
      display: inline-block;
      width: 17px;
      height: 13px;
      margin-left: 10px;
      position: relative;

      &.sharedWithMe {
        background: url("../../../static/img/icon-shared-blue.svg");
      }
    }

    .owner-name {
      color: $color-grey60;
      margin-left: 6px;
      font-size: $font-size-medium;
      line-height: 120%;
      white-space: nowrap;
    }
  }

  .project-controls {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    padding: 0 24px;

    button {
      height: 30px;
      border-radius: 2px;
      border: none;
      outline: none;
      margin-left: 10px;
      font-size: $font-size-medium;
      cursor: pointer;
      color: $color-grey20;
      display: none;

      &.open-project {
        width: 140px;
        background-color: $color-red;
        color: $color-white;

        &:hover {
          background-color: $color-darkred;
        }

        &:active { background-color: $color-grey20; }
      }

      &.share-project,
      &.leave-project,
      &.update-project,
      &.duplicate-project,
      &.is-shared-project,
      &.options-menu-btn {
        padding-left: 34px;
        padding-right: 10px;
        background: $color-grey90 none left 8px center no-repeat;

        &:hover { background-color: $color-grey80;  }

        &:active { background-color: $color-grey60; }
      }

      &.options-menu-btn {
        display: block !important;
        background-position: center;
        padding-left: 20px;
        background-image: url("../../../static/img/icon-ellipsis.svg");
      }

      &.is-shared-project {
        background-image: url("../../../static/img/icon-is-shared.svg");
      }

      &.share-project {
        background-image: url("../../../static/img/icon-share.svg");
      }

      &.leave-project {
        background-image: url("../../../static/img/icon-leave.svg");
      }

      &.update-project {
        background-image: url("../../../static/img/icon-edit.svg");
      }

      &.duplicate-project {
        background-image: url("../../../static/img/icon-duplicate.svg");
      }

      &.delete-project {
        width: 30px;
        background-color: rgba($color-red, 0.15);
        background-image: url("../../../static/img/icon-trash.svg");
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          background-color: rgba($color-red, 0.35);
        }
      }
    }

    &.options-menu-open {

      button {
        display: block;
        transition: .1s opacity ease-in-out;

        &.options-menu-btn {
          background-image: url("../../../static/img/icon-check.svg");
        }
      }

      &.project-controls {
        background: $color-white;

        &::before {
          content: "";
          width: 75px;
          height: 100%;
          position: absolute;
          left: 0;
          transform: translateX(-75px);
          background: linear-gradient(270deg, $color-white 0%, rgba($color-white, 0) 100%);
        }
      }
    }

    .loading {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: sub;
      margin-right: 10px;
      background: url("../../../static/img/icon-progress.svg") center center no-repeat;
      animation: spin .6s infinite linear;
      @keyframes spin {

        100% {
          -moz-transform:rotate(360deg);
          -o-transform:rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
  }

  &:hover {
    box-shadow: 0px 1px 3px rgba($color-black, 0.1);
    background-color: $color-white;
  }

  &.deleting {
    cursor: auto;

    &:not(:hover) {

      .project-controls {
        opacity: 1;
      }
    }

    .customer-name.muted {
      color: $color-grey60;
    }

    .delete-project {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: sub;
      margin-right: 10px;
      background: url("../../../static/img/icon-progress.svg") center center no-repeat;
      animation: spin .6s infinite linear;
      @keyframes spin {

        100% {
          -moz-transform:rotate(360deg);
          -o-transform:rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
  }

  &.delete-failed {
    cursor: auto;

    &:not(:hover) {

      .project-controls {
        opacity: 1;
      }
    }

    .delete-project,
    .update-project,
    .duplicate-project {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: sub;
      margin-right: 10px;
      text-align: center;
      color: $color-red;
    }
  }
}

.shared-list {
  z-index: 2;

  &:before {
    content: "";
    border: 10px solid transparent;
    border-bottom-color: $color-grey90;
    position: absolute;
    top: -20px;
    left: 7px;
  }
  display: block;
  position: absolute;
  left: -13px;
  top: 27px;
  background-color: $color-grey90;
  border: 1px solid $color-grey70;
  border-radius: 2px;
  padding: 10px 20px;
  box-shadow: 0px 0px 20px rgba($color-black, 0.3);
  font-style: normal;
}