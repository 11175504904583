@import "theme.scss";

.error-view-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .error-view-info-wrapper {
    width: 480px;

    h1,
    p {
      color: $color-black;
      text-align: left;
    }

    h1 {
      font-weight: $font-weight-bold;
      font-size: $font-size-heading-medium;
      line-height: 150%;
      margin-bottom: 20px;
    }

    p {
      font-size: $font-size-medium;
      line-height: 150%;
      margin-bottom: 30px;
    }

    .error-view-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button {
        width: 100%;
        font-weight: $font-weight-bold;
        font-size: $font-size-medium;
        line-height: 115%;
        border: none;
        padding: 14px 0;
        margin-bottom: 10px;
        cursor: pointer;

        &.primary-button {
          color: $color-white;
          background: $color-red;
          border-radius: 4px;
        }

        &.text-only-button {
          color: $color-red;
          background-color: transparent;
        }
      }
    }
  }
}
