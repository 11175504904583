@import "theme.scss";

button.toggle-button {
  transition: .1s all ease-out;
  margin: 10px 0;
  padding: 0 4px;
  width: 40px;
  height: 22px;
  border: none;
  border-radius: 50px;
  background: $color-grey60;
  cursor: pointer;

  &.checked {
    background: $color-interactiveblue;
  }

  &.checked:before {
    transform: translateX(16px);
  }

  &:disabled {
    opacity: 0.6;
    cursor: auto;
  }

  &.small {
    width: 24px;
    height: 14px;
    padding: 0 2px;

    &:before {
      height: 10px;
      width: 10px;
    }

    &.checked:before {
      transform: translateX(10px);
    }
  }

  &:before {
    transition: .1s all ease-out;
    content: "";
    display: block;
    background: $color-white;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    transform: translateX(0px);
  }
}

