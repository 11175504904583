@import "theme.scss";

.TextSelect {
  font-size: $font-size-medium;
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    margin-top: -4px;
  }
  
  .select__value-container {
    padding: 0px;
  }
  
  .select__control {

    svg {
      fill: $color-black;
    }
    background: none;
    border-radius: 2px;
    min-height: 20px;
    border: none;
    box-shadow: none;

    span {
      display: none;
    }
    
    &:hover {
      border: none;

      div{
        color: $color-blue;
      }

      svg {
        fill: $color-blue;
      }
    }
  }

  .select__single-value,
  .select__placeholder {
    max-width: 100%;
    position: initial;
    transform: none;
  }

  .select__menu {
    width: 150px;
    z-index: 3;

    .select__option:nth-child(1) {
      border-bottom: 1px solid $color-grey80;
    }
  }

  .select__indicator {
    padding: 0px;
    margin-left: -4px;
    margin-top: 3px;

    svg {
      height: 14px;
    }
  }

  &.DeploymentSelect {
    height: 29px;
    gap: 10px;
    min-width: 143px;

    &.TextSelect{
      justify-content: flex-end;
    }
    
    p {
      opacity: 0.5;
      border-bottom: 1px solid rgba($color-black, 0);
      overflow: visible;
    }

    .select__value-container {
      flex-wrap: nowrap;
    }

    .select__control {
      border-bottom: 1px dashed rgba($color-black, 0.5);
      height: 29px;

      .deployment-icon {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 5px;

        svg {
          margin: 0;
        }
      }

      &:hover {

        div{
          color: $color-black;
        }
  
        svg {
          fill: $color-black;
        }
      }

      &.select__control--is-disabled {
        border-bottom: 1px dashed rgba($color-black, 0);
        opacity: 0.5;

        .select__indicators {
          display:none;
        }
      }
    }

    .select__single-value,
    .select__placeholder {
      color: $color-black;
    }

    .select__indicator {
      padding: 0px;
      margin-left: 3px;
  
      svg {
        height: 14px;
      }
    }
  }
}
