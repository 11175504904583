@import "theme.scss";

table.wb-table {
  border-radius: 2px;
  border-collapse: collapse;
  width: 100%;

  thead {
    opacity: 0.5;
  }

  tbody {
    background: $color-white;
    margin-top: 15px;
    border-radius: 2px;
    box-shadow: 0px 0px 5px rgba($color-black, 0.2);
  }

  td, th {
    padding: 6px 14px;
    line-height: 212%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    color: $color-grey60;
    font-weight: $font-weight-normal;
    padding-bottom: 5px;
  }

  th:last-child {
    text-align: right;
  }

  td {
    color: $color-black;
    font-weight: $font-weight-light;
    border-bottom: 1px solid rgba($color-black,0.1);

    &.empty {
      border:none;
    }
  }

  td:last-child {
    text-align: right;

    &.empty {
      text-align: center;
    }
  }

  tr {

    &:hover {
      background: rgba($color-interactiveblue, 0.063);
    }
  }

  tr.region {
    background: $color-grey90;
  }

  tr.disabled {

    td {
      color: $color-grey60;
      font-weight: $font-weight-light;
    }
  }
}
