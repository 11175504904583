@import "theme.scss";

.list-view {
  display: flex;
  width: 100%;

  .list {
    z-index: 1;
    right: 185px;
    flex: 1;
    padding-bottom: 35px;

    .list-margins {
      margin-left: 185px;
      padding: 0 70px;

      .list-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 42px;
        z-index: 2;
        background-color: $color-grey90;
        padding-top: 9px;
        padding-bottom: 8px;
      }

      .shadow-bottom {
        position: fixed;
        z-index: 3;
        left: 0;
        width: 100%;
        box-shadow: 0 3px 4px 1px $color-grey70;
        -moz-box-shadow: 0 3px 4px 1px $color-grey70;
        -webkit-box-shadow: 0 3px 4px 1px $color-grey70;
      }

      .projects-sort-options {
        display: flex;
        align-items: center;
        position: sticky;
        top: 137px;
        z-index: 2;
        background-color: $color-grey90;
        padding-bottom: 20px;
        font-size: $font-size-large;
        color: $color-grey60;
        user-select: none;

        .spacer {
          flex-grow: 1;
        }
        

        .sort-label {
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: $font-weight-normal;
          font-size: $font-size-medium;
          line-height: 100%;
          color: $color-grey20;
          padding: 12px;
          white-space: nowrap;
          height: 34px;
        }
      }

      .sort-container {
        display: flex;
        flex-basis: 1;
        flex-grow: 0;
        justify-content: flex-end;
        margin-left: 18px;
      }

      .rotate-arrow{       
        font-style: normal;
        font-weight: $font-weight-normal;
        font-size: $font-size-medium;
        line-height: 100%;
        color: $color-grey20;
        cursor: pointer;
        transition: color 0.2s;
        background: $color-white;
        box-shadow: 0px 1px 3px rgba($color-black, 0.1);
        border-radius: 3px;
        height: 34px;
        padding: 12px;
        margin-left: 6px;

        

        svg {
          transform: rotate(270deg)
        }
        

        &.descending {

          svg {
            transform: rotate(90deg)
          }
        }
      }

      .select-wrapper {
        font-style: normal;
        font-weight: $font-weight-normal;
        font-size: $font-size-medium;
        line-height: 100%;
        color: $color-grey20;
        border-radius: 3px;
        width: 140px;

        *{ border-style:none;}       

        div {
          cursor: pointer;
        }

        .select__control {
          box-shadow: 0px 1px 3px rgba($color-black, 0.1);
          min-height: 34px;
          height: 34px;

          &.select__control--menu-is-open{
            background-color: $color-grey80;
          }
        }

        svg {
          width: 25px;
          visibility: visible;
          overflow: visible;
        }
      }

      .projects-list {
        margin: 0;
        padding: 15px 2px 0;
        list-style: none;
        list-style-type: none;
        background-color: $color-grey90;
      }

      .no-projects {
        text-align: center;
        border-radius: 2px;
        border: 1px solid $color-grey70;
        color: $color-grey60;
        padding: 30px 0;

        h3 { font-size: $font-size-large; margin-bottom: 0; }

        p { font-size: $font-size-medium; margin-top: 0; }
        
        .redirect{ text-decoration: none; color: $color-blue; cursor: pointer;
        }

      }

      .loading-projects {
        text-align: center;
        border-radius: 2px;
        border: 1px solid $color-grey70;
        animation: pulse 3s infinite linear;
        @keyframes pulse {

          0%    { border-color: rgba($color-red,.0); }

          50%   { border-color: rgba($color-red,.5); }

          100%  { border-color: rgba($color-red, 0); }
        }
        color: $color-black;
        padding: 60px 0;

        .loading-message {
          font-size: $font-size-large;
          line-height: 130%;
          height: 20px;
          vertical-align: middle;

          .spinner {
            display: inline-block;
            vertical-align: sub;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            background: url("../../../static/img/icon-progress.svg") center center no-repeat;
            animation: spin .6s infinite linear;
            @keyframes spin {

              100% {
                -moz-transform:rotate(360deg);
                -o-transform:rotate(360deg);
                transform:rotate(360deg);
              }
            }

          }
        }
      }
    }
  }

}

.sidenav{
  padding-top: 20px;
  position: fixed;
  background: $color-grey90;
  z-index: 2;
  left: 0;
  height: 100%;
  width: 185px;
  border-right: 1px solid $color-grey80;

  .item{
    padding: 8px 22px;
    color: $color-grey50;
  }
  
  .title{
    margin-top: 15px;
    font-size: $font-size-medium;
    font-weight: $font-weight-normal;
    line-height: 130%;
  }

  .option{
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: 130%;
    cursor: pointer;

    &:hover{
      background: $color-white;
    }
  }

  .selected{
    border-right: 2px solid $color-red;
    color: $color-grey15;
    background: $color-white;
  }
}

.pagination{
  margin: 50px 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 10px;
  
  .page-link{
    font-size: $font-size-large;
    color: $color-grey20;
    cursor: pointer;
    padding: 15px;
    box-shadow: 0px 1px 3px rgba($color-black, 0.1);
    border-radius: 2px;

    &:hover{
      background: $color-white;
    }
  }

  .active{
    color: $color-red;
    background: $color-white;
  }

  .disabled-item{
    cursor: grab;

    &:hover{background: transparent;}
  }

}

.cards-container{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.maintenance-banner{
  margin-top: 20px;
  border: 1px solid $color-yellow;
  border-radius: 2px;
  padding: 15px;
  text-align: center;
}