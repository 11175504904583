@import "theme.scss";


.map-key-container{
  z-index: 9999;
  height: fit-content;
  position: fixed;
  bottom: 0;
  margin-left: 10px;

  .title{
    color: $color-blue;
    font-size: $font-size-small;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    background: rgba($color-white, 0.6);
    cursor: pointer;
    width: 120px;
    text-align: center;
  }

  .content{
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    background: rgba($color-white, 0.6);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 3px;
    border-radius: 0px 5px 0px 0px;
    padding: 0px 15px;
    font-size: $font-size-small;
    color: $color-grey50;
    text-wrap: nowrap;

    &.visible{
      padding: 5px 15px;
      max-height: 122px;
    }

    .item{
      display: flex;

      .icon-section{
        width: 40px;
        margin-right: 5px;
        display: flex;
        gap: 3px;
        align-items: center;
        justify-content: center;

        span{
          color: $color-red;
          font-weight: $font-weight-bold;
          font-size: $font-size-small;
        }

        hr{
          border: none;
          margin: 0;
          height: 3px;
          width: 18.5px;
          border-radius: 5px;

        }

        .red-solid{
          border-top: 3px solid $color-red;
        }

        .red-dotted{
          margin: 0;
          border-top: 3px dotted $color-red;
        }

        .yellow{
          margin: 0;
          border: none;
          border-top: 3px solid $color-yellow;
          align-items: center;
        }
      }
    }
  }
}