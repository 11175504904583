@import "theme.scss";

.importation-issues-modal {
  width: 1000px;
  overflow-x: hidden;
  padding: 26px 40px;

  .modal-wrapper {

    .modal-header {
      align-items: center;
      position: relative;

      h3 {
        font-weight: $font-weight-bold;
        font-size: $font-size-heading-medium;
        line-height: 160%;
        letter-spacing: 0.15px;
        color: $color-black;
      }

      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 120%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 1px solid rgba($color-black, 0.2);
      }
    }

    table.importation-issues-table {
      display: inline-table;
      overflow: hidden;
      box-shadow: 0px 0px 5px rgba($color-black, 0.2);
      margin-top: 20px;

      tbody {
        margin: 0;
        box-shadow: none;
      }

      td {
        text-align: left !important;
        white-space: break-spaces;
        display: flex;
        align-items: center;
        line-height: 175%;
        padding: 8px 14px;

        p {
          margin: 0;
          display: flex;

          &.row-message {
            flex-basis: 93%;
          }

          &.row-number {
            color: $color-grey60;
            flex-basis: 7%;
            max-width: 7%;
            margin-right: 10px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
